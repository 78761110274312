import {
	createContext,
	useContext,
	useState,
	useCallback,
	ReactNode,
	useEffect,
} from 'react' 
import helperFunc from '../helpers/helperFunc'
import f7WebNetwork from '../api/customApi/f7WebNetwork'

const AuthContext = createContext<any>({})

export function useAuthContext() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuthContext must be used within an AuthProvider')
	}
	return context
}

const authSessionKey = 'user'

export function AuthProvider({ children }: { children: ReactNode }) {
	const [user, setUser] = useState(
		localStorage.getItem(authSessionKey)
			? JSON.parse(localStorage.getItem(authSessionKey) || '{}')
			: undefined
	)

	useEffect(()=>{
		validateUser()
	}, []);

	const saveSession = useCallback(
		(user: string) => {
			localStorage.setItem(authSessionKey, user)
			setUser(user)
		},
		[setUser]
	)

	const validateUser = async () => {
		var userInfo = helperFunc.getUserInformation();
		var userID = "0";
		try {
			userID = userInfo.UserId;
		} catch (error) {

		}
		const params = {
			userId: (userID),
		}
		f7WebNetwork.get("/adminuser/checkUser", params)
			.then(result => {

				var data: any = result.data;
			 
				if (data !== true) {
					localStorage.removeItem(authSessionKey)
					setUser(undefined)
				}

			})

	}

	const removeSession = useCallback(() => {
		localStorage.removeItem(authSessionKey)
		setUser(undefined)
	}, [setUser])

	return (
		<AuthContext.Provider
			value={{
				user,
				isAuthenticated: Boolean(user),
				saveSession,
				removeSession,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
