import globalTypes from "../../../common/helpers/globalTypes";

 

const apiOperations={

 
    GetWebAddress: async function () {
   

        // return "http://localhost:5098";
        return globalTypes.webProjectUrl;
        // return this.serviceAddress + this.serviceName + this.svcPath ;
    },
    objToQueryString(obj : any) {
        const keyValuePairs = [];
        for (const key in obj) {

            keyValuePairs.push((key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    },
    apiSuccess(result: any =[]) {
        return ({
            isSuccess: true,
            data: result,
            errorMessage: ""
        });
    },
    apiError(errorMsg: any) {
        return ({
            isSuccess: false,
            errorMessage: errorMsg,
            data: null
        });
    },
    
}

export default apiOperations;