import Axios from "axios"; 
import apiOperations from "./apiOperations";




const f7WebNetwork = {

    get: async function (method : string, obj?: any) {
        var queryString = "";
        if (obj) {
            queryString = "/?" + apiOperations.objToQueryString(obj);
        }

    
     
        var serviceAddress = await apiOperations.GetWebAddress();
   
        const url = serviceAddress + method + queryString;
 
 
        try {
            return apiOperations.apiSuccess(await (await Axios.get(url)).data);

        } catch (error) {

            return apiOperations.apiError(error);
        }
    },

    getFromUrl: async function (method: string, obj?: any) {
        var queryString = "";
        if (obj) {
            queryString = "/?" + apiOperations.objToQueryString(obj);
        }

        const url =  method + queryString;
 
 
        try {
            return apiOperations.apiSuccess(await (await Axios.get(url)).data);

        } catch (error) {

            return apiOperations.apiError(error);
        }
    },

    post: async function (method : string, obj: any) {
         const serviceAddress = await apiOperations.GetWebAddress();
        const url = serviceAddress + method;

        try { 
            const res = await (await Axios.post(url, obj,  {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    
                }
             })).data;
            var data = apiOperations.apiSuccess(res);
             return data;

        } catch (error) {
          console.log(error);
            return apiOperations.apiError(error);
        }
    },
    postFormData: async function (method : any, params: any) {

        const url = await apiOperations.GetWebAddress() + method;
      
    
        try {
          let res = await fetch(url, {
            method: "POST",
            body: params, 
          });
       
          var result2 = await res.text();
          
          if (res.ok) {
            return apiOperations.apiSuccess({
              result: {
                data : result2
              }
            }); 
      
          }else {
      
            return apiOperations.apiError({
              errorMsg: result2
            }); 
          } 
        } catch (error) {
          return apiOperations.apiError({
            errorMsg: error
          }); 
        }
   
    
      },
}

export default f7WebNetwork;