import { LayoutDir } from '../../types'

type LayoutDirectionProps = {
	handleChangeLayoutDir: (value: LayoutDir) => void
	layoutDirection: LayoutDir
}
const LayoutDirection = ({
	handleChangeLayoutDir,
	layoutDirection,
}: LayoutDirectionProps) => {
	return (
		<div className="mb-4">
			<h5 className="font-semibold text-sm mb-3">Choose Layout</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-dir"
						id="dir-ltr"
						onChange={() => handleChangeLayoutDir('ltr')}
						checked={layoutDirection === 'ltr'}
					/>
					<label className="ms-2" htmlFor="dir-ltr">
						LTR
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-dir"
						id="dir-rtl"
						onChange={() => handleChangeLayoutDir('rtl')}
						checked={layoutDirection === 'rtl'}
					/>
					<label className="ms-2" htmlFor="dir-rtl">
						{' '}
						RTL{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default LayoutDirection
