import { LayoutTheme as LayoutThemeType } from '../../types'

type LayoutThemeProps = {
	handleChangeLayoutTheme: (value: LayoutThemeType) => void
	layoutTheme: LayoutThemeType
}
const LayoutTheme = ({
	handleChangeLayoutTheme,
	layoutTheme,
}: LayoutThemeProps) => {
	return (
		<div className="mb-4">
			<h5 className="font-semibold text-sm mb-3">Color Scheme</h5>
			<div className="flex flex-col gap-2">
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-mode"
						id="layout-color-light"
						onChange={() => handleChangeLayoutTheme('light')}
						checked={layoutTheme == 'light'}
					/>
					<label className="ms-2" htmlFor="layout-color-light">
						Light
					</label>
				</div>
				<div className="flex items-center">
					<input
						className="form-switch form-switch-sm text-primary"
						type="checkbox"
						name="data-mode"
						id="layout-color-dark"
						onChange={() => handleChangeLayoutTheme('dark')}
						checked={layoutTheme == 'dark'}
					/>
					<label className="ms-2" htmlFor="layout-color-dark">
						{' '}
						Dark{' '}
					</label>
				</div>
			</div>
		</div>
	)
}

export default LayoutTheme
