import { ReactNode, createContext, useContext, useState } from 'react'
import {
	LayoutDir,
	LayoutMode,
	LayoutSidenav,
	LayoutState,
	LayoutTheme,
	LayoutTopbarTheme,
	LayoutType,
	MenuType,
} from '../../types'

const ThemeContext = createContext<LayoutType | undefined>(undefined)

export function useThemeContext() {
	const context = useContext(ThemeContext)
	if (context === undefined) {
		throw new Error('useThemeContext must be used within an ThemeProvider')
	}
	return context
}

export function ThemeProvider({ children }: { children: ReactNode }) {
	const urlSearchParams = new URLSearchParams(window.location.search)
	const params = Object.fromEntries(urlSearchParams.entries())

	const INIT_STATE: LayoutState = {
		theme: params['layout_theme'] == 'dark' ? 'dark' : 'light',
		dir: params['dir'] == 'rtl' ? 'rtl' : 'ltr',
		mode: params['layout_mode'] == 'boxed' ? 'boxed' : 'fluid',
		menuType: params['layout_type'] == 'horizontal' ? 'horizontal' : 'vertical',
		sidenav: {
			mode: 'default',
			position:
				params['sidenav_position'] == 'scrollable' ? 'scrollable' : 'fixed',
			theme:
				params['sidenav_theme'] == 'brand'
					? 'brand'
					: params['sidenav_theme'] == 'dark'
					? 'dark'
					: params['sidenav_theme'] == 'gradient'
					? 'gradient'
					: 'light',
		},
		topbarTheme: params['topbar_theme'] == 'dark' ? 'dark' : 'light',
		showRightsideBar: false,
		pageTitle: 'Dashboard',
	}

	const [settings, setSettings] = useState<LayoutState>(INIT_STATE)

	// update settigns
	const updateSettings = (_newSettings: Partial<LayoutType['settings']>) => {
		setSettings({ ...settings, ..._newSettings })
	}

	const updateTheme = (newTheme: LayoutTheme) => {
		updateSettings({ ...settings, theme: newTheme })
	}

	const updateDir = (newDir: LayoutDir) =>
		updateSettings({ ...settings, dir: newDir })

	const updateMode = (newMode: LayoutMode) =>
		updateSettings({ ...settings, mode: newMode })

	const updateMenuType = (newMenuType: MenuType) =>
		updateSettings({ ...settings, menuType: newMenuType })

	const updateSidenav = (newSidenav: Partial<LayoutSidenav>) =>
		updateSettings({ sidenav: { ...settings.sidenav, ...newSidenav } })

	const updateTopbar = (newTopbar: LayoutTopbarTheme) =>
		updateSettings({ ...settings, topbarTheme: newTopbar })

	const updateShowRightsideBar = (show: LayoutState['showRightsideBar']) =>
		setSettings({ ...settings, showRightsideBar: show })

	const updatePageTitle = (newPageTitle: LayoutState['pageTitle']) =>
		setSettings({ ...settings, pageTitle: newPageTitle })

	const resetSettings = () => {
		setSettings(INIT_STATE)
	}

	return (
		<ThemeContext.Provider
			value={{
				settings,
				updateTheme,
				updateDir,
				updateMode,
				updateMenuType,
				updateSidenav,
				updateShowRightsideBar,
				updateTopbar,
				updatePageTitle,
				resetSettings,
			}}
		>
			{children}
		</ThemeContext.Provider>
	)
}
